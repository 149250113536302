import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Label, SubTitle } from '@/components/TextComponents/textStyles'
import { ColorPaletteMain as Colors } from '@/styles/ColorPalette'

interface CardProps {
  image: any
  title: string
  description: string
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
  return (
    <Wrapper>
      <Content>
        <Image image={props.image.gatsbyImageData} alt='' />
        <TitleWrapper>
          <SubTitleWrapper level="2" isSmall>{props.title}</SubTitleWrapper>
          <CardLabel level="1" isSmall>{props.description}</CardLabel>
        </TitleWrapper>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  max-width: 160px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #EDEEF1;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.04);

  &::first-child {
    margin-left: 0px;
  }

  &::last-child {
    margin-right: 0px;
  }
`

const CardLabel = styled(Label)`
  color: ${Colors.primaryColors.ink2};
`

const SubTitleWrapper = styled(SubTitle)`
  margin: 4px 0;
`

const Content = styled.div`
  padding: 16px;
`

const TitleWrapper = styled.div`
  margin-top: 19px;
`

const Image = styled(GatsbyImage)`
  border-radius: 6px;
`