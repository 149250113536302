import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from "styled-components"
import GlobalStyle from '@/styles/GlobalStyles'
import NotFound from './404'
import { Card } from '@/components/BlockComponents/Card'
import { Title,  Paragraph, DividerSubTitle } from '@/components/TextComponents/textStyles'

const Team = ({ data }) => {
  const _data = data.directus?.clinic[0]

  const clinicId = _data.clinic_id ?? ''

  const textData = {
    headline: data.directus?.TextGlobals.team_headline ?? '',
    intro: data.directus?.clinic[0].name ?? '',
    doctorsSubtitle: data.directus?.TextGlobals.team_doctors_headline ?? '',
    staffSubtitle: data.directus?.TextGlobals.team_staff_headline ?? '',
  }

  const person = _data.person.map((person) => ({
    id: person.id ?? '',
    image: person.image?.imageFile?.childImageSharp ?? '',
    name: person.name ?? '',
    jobDescription: person.job_description ?? '',
    isDoctor: person.is_doctor ?? '',
  }))

  if (!_data) {
    return <NotFound />
  }

  return (
    <main>
      <GlobalStyle />
      <Title as="h4" level="4">{textData.headline}</Title>
      <Paragraph level="1" margin="8px 0 40px" isSmall>
        {textData.intro}
      </Paragraph>
      <DividerSubTitle>{textData.doctorsSubtitle}</DividerSubTitle>
      <CardSection>
        {!!person &&
          person.map((item) => {
            const linkToMember = `${process.env.GATSBY_BASE_URL}/${clinicId}/team/${item.id}`
            return (
              !!item.isDoctor && (
                <a href={linkToMember} key={item.id}>
                  <Card image={item.image} title={item.name} description={item.jobDescription} />
                </a>
              )
            )
          })}
      </CardSection>
      <DividerSubTitle>{textData.staffSubtitle}</DividerSubTitle>
      <CardSection>
        {!!person &&
          person.map((item) => {
            const linkToMember = `${process.env.GATSBY_BASE_URL}/${clinicId}/team/${item.id}`
            return (
              !item.isDoctor && (
                <a href={linkToMember} key={item.id}>
                  <Card image={item.image} title={item.name} description={item.jobDescription} />
                </a>
              )
            )
          })}
      </CardSection>
    </main>
  )
}

const CardSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 12px;
  column-gap: 8px;
  margin: 12px -5px 36px;
  @media (min-width: 768px) {
    justify-content: center;
  }
`

export default Team

export const query = graphql`
  query Team($clinic: String) {
    directus {
      TextGlobals {
        team_headline
        team_doctors_headline
        team_staff_headline
      }
      clinic(filter: { clinic_id: { _eq: $clinic } }) {
        clinic_id
        name
        person {
          id
          name
          job_description
          is_doctor
          image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 128, height: 104, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
